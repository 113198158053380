import {User, UserByOrg} from "../../model/UserModels";


export const INIT_USER: UserByOrg = {
  id: "",
  user: {
    username: "",
    password: "",
    confirmPassword: "",
    roles: []
  } as User,
  organization: null,
  orgId: "",
  createDate: "",
  createdBy: "",
  modifiedBy: "",
  modifyDate: "",
  active: true,
};

export const USER_VARIABLE = {
  IS_CHANGE_PASSWORD: "user.isChangePassword"
}