import { FC, useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { IRole } from "../model/ManagerModel";
import { deleteRole, getListRole } from "../services/ManagerServices";
import { toast } from "react-toastify";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { RolesDialog } from "./Dialog/RolesDialog";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import AppContext from "../../../AppContext";
import { PAGINATION } from "../../../Constant";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { OCTTable } from "@oceantech/oceantech-ui";

const RoleList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [roleInfo, setRoleInfo] = useState<IRole>({} as IRole);
  const [openRoleDialog, setOpenRoleDialog] = useState<boolean>(false);
  const [roleList, setRoleList] = useState<IRole[]>([]);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    if (!openRoleDialog) {
      loadListRole();
    }
  }, [searchObject, openRoleDialog]);

  const handleOpenRoleDialog = (data: IRole) => {
    setOpenRoleDialog(true);
    setRoleInfo(data);
  };
  const handleCloseRoleDialog = () => {
    setOpenRoleDialog(false);
    setRoleInfo({} as IRole);
  };

  const loadListRole = async () => {
    try {
      setPageLoading(true);
      const res = await getListRole(searchObject);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        let totalElements = data?.pageable?.totalElements || 0;
        let totalPageValue =
          totalElements % searchObject?.pageSize === 0
            ? totalElements / searchObject?.pageSize
            : Math.floor(totalElements / searchObject?.pageSize) + 1;
        setTotalPage(totalPageValue || 0);
        setRoleList(data?.content);
        setTotalElements(totalElements);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteRole = async (ids: string[]) => {
    try {
      const res = await deleteRole(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListRole();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const roleColumn = [
    {
      name: "STT",
      field: "stt",
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("ROLE.NAME"),
      field: "name",
      cellStyle: {
        textAlign: "left",
        minWidth: "170px",
      },
    },
    {
      name: lang("ROLE.DES"),
      field: "description",
      cellStyle: {
        textAlign: "left",
        minWidth: "170px",
      },
    },
    {
      name: lang("AUTH"),
      field: "authorities",
      cellStyle: {
        textAlign: "left",
        minWidth: "400px",
      },
      render: (row: any) =>
        row.authorities?.length > 0 &&
        row.authorities.map((item: any, index: number) => (
          <span key={index}>
            <span>{item}</span>
            <span className="color-red spaces fw-600">; </span>
          </span>
        )),
    },
  ];

  return (
    <>
      <Row className="role-auth">
        <Col xs={12}>
          <div className="table-custom-role">
            <OCTTable
              title={lang("ROLE.LIST")}
              isActionTableTab
              id="roleList"
              data={roleList}
              columns={roleColumn}
              buttonAdd
              isSearchable
              handleSearch={loadListRole}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              type={TYPE.MULTILINE}
              totalPages={totalPage}
              totalElements={totalElements}
              handleOpenDialog={() => setOpenRoleDialog(true)}
              handleDoubleClick={handleOpenRoleDialog}
              handleDeleteList={(ids: any) => handleDeleteRole(ids)}
            />
          </div>
        </Col>
      </Row>
      {openRoleDialog && (
        <RolesDialog
          handleCloseDialog={handleCloseRoleDialog}
          roleInfo={roleInfo}
          handleReload={loadListRole}
        />
      )}
    </>
  );
};
export { RoleList };
