import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import {PERMISSIONS, PATH_ROUTES, AUTHORITIES, REDIRECT_PATH} from "../Constant";
import { UserList } from "../modules/manager/component/UserList";
import { HomePage } from "../pages/Homepage/HomePage";
import {OrganizationList} from "../modules/manager/component/OrganizationList";
import {RoleList} from "../modules/manager/component/RoleList";
import {AuthorityList} from "../modules/manager/component/AuthorityList";
import { DepartmentList } from "../modules/manager/component/DepartmentList";
import {hasAccessAuthority} from "../modules/utils/FunctionUtils";
import {Person} from "../modules/person/Person";
import React from "react";

interface PrivateRouteProps {
  auth: string;
  ability: string;
  component: React.ComponentType<any>;
  redirect: string;
}

const PrivateRoutes = () => {
  const PrivateRoute: React.FC<PrivateRouteProps> = ({auth, ability, component: Component, redirect,}) => {
    return hasAccessAuthority(auth, ability) ? (<Component/>) : (<Navigate to={redirect}/>);
  };

  return (
    <Routes>
      <Route index element={<Navigate to="/home" />} />
      <Route path="/*" element={<HomePage />} />
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route
          path={PATH_ROUTES.SYSTEM.USER + "/*"}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.USER.VIEW}
              component={UserList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.ORGANIZATION + "/*"}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.ORGANIZATION.VIEW}
              component={OrganizationList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.DEPARTMENT}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.DEPARTMENT.VIEW}
              component={DepartmentList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.AUTHORITY}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.AUTHORITY.VIEW}
              component={AuthorityList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.ROLE}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.ROLE.VIEW}
              component={RoleList}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        <Route
          path={PATH_ROUTES.SYSTEM.PERSON}
          element={
            <PrivateRoute
              auth={PERMISSIONS.ADMIN}
              ability={AUTHORITIES.PERSON.VIEW}
              component={Person}
              redirect={REDIRECT_PATH.HOME}
            />
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to={REDIRECT_PATH.NOT_FOUND}/>} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };

