import axios from "axios";
import { paramsConfig } from "../../../modules/utils/ParamsUtils";

const API_PATH = process.env.REACT_APP_API_URL;
const API_PHONG_BAN = API_PATH + "/c-department";

export const getEmployeeIdByUserId = (id: any) => {
  let url = `${API_PATH}/employee/get-by-user-id/${id}`;
  return axios.get(url);
};

export const searchByPage = (searchObject: any) => {
  let url = API_PATH + "/employee/page";
  return axios.get(url, paramsConfig(searchObject));
};

export const searchAllEmployee = (searchObject: any = {}) => {
  let url = API_PATH + "/employee/page";
  return axios.get(url, paramsConfig(searchObject));
};

export const searchPhongBan = (searchObj: any) => {
  return axios.post(`${API_PHONG_BAN}/page`, searchObj);
};