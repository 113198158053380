import axios, {AxiosResponse} from "axios";
import {APIResponse} from "../../models/models";
import {PersonModels} from "../model/PersonModels";


const API_URL_ORG = process.env.REACT_APP_XADMIN_ENDPOINT + "/person";

export const getListPersons = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || 10,
            pageIndex: searchData?.pageIndex || 1,
        },
    };
    let url = `${API_URL_ORG}/search`;
    return axios.get(url, config);
};

export const updateOrganization = (id: string, org: PersonModels): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.put(url, org);
};

export const addNewOrganization = (org: PersonModels): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}`;
    return axios.post(url, org);
};

export const getOrganizationById = (id?: any) => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.get(url);
};

export const deleteListOrganization = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params :{
            ids:ids?.toString()
        }
    }
    let url = `${API_URL_ORG}/list-id`;
    return axios.delete(url,config);
};

export const deleteOrganization = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.delete(url);
};