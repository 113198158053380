import { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { IAuth } from "../model/ManagerModel";
import { deleteAuth, getListAuth } from "../services/ManagerServices";
import { toast } from "react-toastify";
import { AuthDialog } from "./Dialog/AuthDialog";
import { RESPONSE_STATUS_CODE, TYPE } from "../../utils/Constant";
import useMultiLanguage from "../../../hook/useMultiLanguage";
import { IObject } from "../../models/models";
import { INIT_SEARCH_OBJECT } from "../../constant";
import { PAGINATION } from "../../../Constant";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../AppFunction";
import AppContext from "../../../AppContext";
import { OCTTable } from "@oceantech/oceantech-ui";
const AuthorityList: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [authList, setAuthList] = useState<IAuth[]>([]);
  const [authInfo, setAuthInfo] = useState<IAuth>({} as IAuth);
  const [openAuthDialog, setOpenAuthDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);
  useEffect(() => {
    // if (!openAuthDialog) {
    loadListAuth();
    // }
  }, [searchObject]);
  const handleOpenAuthDialog = (data: IAuth) => {
    setOpenAuthDialog(true);
    setAuthInfo(data);
  };
  const handleCloseAuthDialog = () => {
    setOpenAuthDialog(false);
    setAuthInfo({} as IAuth);
  };
  const loadListAuth = async () => {
    try {
      setPageLoading(true);
      const res = await getListAuth(searchObject);
      const { code, data } = res.data;
      let pageSize = searchObject.pageSize;
      let totalElements = data?.pageable?.totalElements;
      let totalPages =
        totalElements % pageSize === 0
          ? totalElements / pageSize
          : Math.floor(totalElements / pageSize) + 1;
      if (isSuccessfulResponse(code)) {
        setAuthList(data?.content);
        setTotalPage(totalPages);
        setTotalElements(totalElements);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleDeleteAuth = async (ids: string[]) => {
    try {
      const res = await deleteAuth(ids);
      if (res?.data?.code === RESPONSE_STATUS_CODE.SUCCESS) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        await loadListAuth();
        return true;
      } else toast.error(`${res?.data?.message}`);
    } catch (error) {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const authColumn = [
    {
      name: "STT",
      field: "stt",
      cellStyle: {
        //   width: "35px",
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => <span>{stt}</span>,
    },
    {
      name: lang("AUTH.NAME"),
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      name: lang("AUTH.CODE"),
      field: "code",
      cellStyle: {
        textAlign: "left",
      },
    },
  ];
  return (
    <>
      <Row className="role-auth pt-4">
        <Col xs={12}>
          <div className="table-custom-auth">
            <OCTTable
              title={lang("AUTH.LIST")}
              isActionTableTab
              id="authList"
              data={authList}
              columns={authColumn}
              buttonAdd
              isSearchable={true}
              handleSearch={loadListAuth}
              searchObject={searchObject}
              setSearchObject={setSearchObject}
              type={TYPE.MULTILINE}
              totalPages={totalPage}
              totalElements={totalElements}
              numberOfElements={PAGINATION.NUMBER_OF_ELEMENTS_DEFAULT}
              handleOpenDialog={() => setOpenAuthDialog(true)}
              handleDoubleClick={handleOpenAuthDialog}
              handleDeleteList={(ids: any) => handleDeleteAuth(ids)}
            />
          </div>
        </Col>
      </Row>
      {openAuthDialog && (
        <AuthDialog
          handleCloseDialog={handleCloseAuthDialog}
          authInfo={authInfo}
          handleReload={loadListAuth}
        />
      )}
    </>
  );
};
export { AuthorityList };
