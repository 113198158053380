import { useFormik } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  addNewOrganization,
  getListOrganizations,
  getOrganizationByCode,
  updateOrganization,
} from "../../services/OrganizationServices";
import { Org } from "../../model/UserModels";
import Autocomplete from "../../../component/input-field/Autocomplete";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import OCTTextValidator from "../../../component/input-field/OCTTextValidator";
import {
  flatArrayByChildrenName,
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../../AppFunction";
import AppContext from "../../../../AppContext";

interface IProps {
  open?: boolean;
  handleCloseDialog: () => void;
  orgInfo: Org;
}
const OrganizationDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, orgInfo } = props;
  const { lang } = useMultiLanguage();
  const intl = useIntl();
  const { setPageLoading } = useContext(AppContext);
  const [org, setOrg] = useState<Org>(orgInfo);

  useEffect(() => {
    handleGetParentOrg();
  }, []);

  const issueValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    code: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
  });

  const handleSubmit = async (values: Org) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertSubmitData(values);
      const res = orgInfo.id
        ? await updateOrganization(orgInfo.id, dataSubmit)
        : await addNewOrganization(dataSubmit);
      const { code } = res?.data;
      if (isSuccessfulResponse(code)) {
        toast.success(
          orgInfo.id ? lang("TOAST.EDIT.SUCCESS") : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const convertSubmitData = (values: Org) => ({
    id: orgInfo?.id,
    name: values.name,
    code: values.code,
    type: values.type,
    parentId: values.parent?.id,
  });

  const handleGetParentOrg = async () => {
    try {
      // setPageLoading(true);
      let parentCode = org.parentCode || "";
      let res = await getOrganizationByCode(parentCode);
      const { data, code } = res?.data;
      if (isSuccessfulResponse(code)) {
        await setFieldValue("parent", data);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    onSubmit: handleSubmit,
    validationSchema: issueValidationSchema,
    initialValues: orgInfo,
  });

  const handleChangeSelect = (data: any, name: any) => {
    const flatArray = flatArrayByChildrenName(values.subs, "subs");
    const isInvalidParent = flatArray.some((item: any) => item.id === data?.id);
    const duplicateOrg = values?.id === data?.id;

    if (isInvalidParent || duplicateOrg) {
      toast.warning(lang("UNIT.INVALID_PARENT"));
      return;
    }
    setFieldValue(name, data);
  };

  const { setFieldValue, touched, errors, values, handleChange } = formik;

  return (
    <>
      {/*{renderForm}*/}
      <Modal show={true} onHide={handleCloseDialog} size="lg" centered>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {intl.formatMessage({
                id: !orgInfo?.id ? "UNIT.ADD" : "UNIT.EDIT",
              })}
              <i
                className={`bi ${
                  orgInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                } text-primary ps-2 fs-5`}
              ></i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="px-6">
              <Col xs={6}>
                <OCTTextValidator
                  name="name"
                  lable={lang("UNIT.NAME")}
                  type={"text"}
                  onChange={handleChange}
                  value={values.name}
                  errors={errors?.name}
                  touched={touched?.name}
                  isRequired
                />
              </Col>
              <Col xs={6}>
                <OCTTextValidator
                  name="code"
                  lable={lang("UNIT.CODE")}
                  type={"text"}
                  onChange={handleChange}
                  value={values.code}
                  errors={errors?.code}
                  touched={touched?.code}
                  isRequired
                  readOnly={Boolean(orgInfo.id)}
                />
              </Col>
            </Row>
            <Row className="px-6 pt-6">
              <Col xs={6}>
                <OCTTextValidator
                  name="type"
                  lable={lang("UNIT.TYPE")}
                  type={"text"}
                  onChange={handleChange}
                  value={values.type}
                />
              </Col>
              <Col xs={6}>
                <Autocomplete
                  lable={lang("UNIT.PARENT")}
                  options={[]}
                  value={values?.parent || null}
                  name="parent"
                  getOptionValue={(option) => option.name}
                  onChange={handleChangeSelect}
                  searchFunction={getListOrganizations}
                  searchObject={{}}
                  renderChilden="subs"
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className="btn btn-secondary  btn-sm"
              onClick={handleCloseDialog}
            >
              {intl.formatMessage({
                id: "BTN.CANCEL",
              })}
            </Button>
            <Button className="btn btn-primary btn-sm" type="submit">
              {intl.formatMessage({ id: "BTN.SAVE" })}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export { OrganizationDialog };
