import {Col, Form, Row } from "react-bootstrap";
import '../../Manager.scss';
import { findChildrenNamesByValue } from "../const/ManagerCost";

interface IProps {
  data: any[];
  roleAdmin: boolean;
  handleShowListPermission: (keyPermission: string) => void;
  handleCheck: (e: any) => void;
}

const SelectedPermissions: React.FC<IProps> = ({ roleAdmin = false, data = [], handleShowListPermission, handleCheck }) => {
  const mapPermission = (listPermissions: any[], name: string) => {
    return listPermissions.map((item: any, index: number) => {
      return (
        <div key={index} className='relative'>
          <span 
            className={`vertical-lines per-item`}
            data-count={countChildren(item)}
            style={{height: `calc(100% - ${countChildren(item) > 0 ? countChildren(item) * 33 + 4 : 42}px)`}}
          ></span>
          <Form.Group className="mb-3 d-flex">
            <Form.Check
              type="checkbox"
              label={<span className='horizontal-line'>{item.nameNode}</span>}
              checked={roleAdmin ? true : !!item.checked}
              value={item.value}
              onChange={(e: any) => handleCheck(e)}
            />
          </Form.Group>
          {item.children ? (
            <div className='list-permissions-item'>{mapPermission(item.children, item.name)}</div>
          ) : null}
        </div>
      );
    });
  };

  const countChildren = (item: any): number => {
    let value = item?.value;
    let lastChildValue = item?.children?.[item?.children?.length - 1]?.value;
    let lastChildChildrens = item?.children?.[item?.children?.length - 1]?.children;
    if(!lastChildValue || !lastChildChildrens.length) return 0;
    let moreChild: string[] = [];
    let childrens: string[] = findChildrenNamesByValue(data, lastChildValue);
    for (let index: number = 0; index < childrens.length; index++) {
      let splitStr: string[] = childrens[index].split('.');
      let minElement = value.split('.').length;
      let maxElement: number = splitStr.length;
      for (let index: number = 0; index < maxElement - minElement - 1; index++) {
        splitStr.pop();
        let string: string = splitStr.join('.');
        if(!moreChild.includes(string)){
          moreChild.push(string);
        }
      }
    }
    return childrens.length + moreChild.length;
  }

  return (
    <Row>
      {data.map((item, index: number) => {
        return (
            <Col xs={4} key={index}>
              <div className='relative ' key={index}>
                {item.notShow &&
                <span
                  className={`vertical-lines`}
                  data-count={countChildren(item)}
                  style={{height: `calc(100% - ${countChildren(item) > 0 ? countChildren(item) * 33 + 4 : 42}px)`}}
                ></span>}
                {!item.children ? (
                  <i className="bi bi-circle"></i>
                ) : !!item.notShow ? (
                  <i className="bi bi-dash-circle" onClick={() => handleShowListPermission(item.value)}></i>
                ) : (
                  <i className="bi bi-plus-circle" onClick={() => handleShowListPermission(item.value)}></i>
                )}
                <Form.Group className="mb-3 d-flex">
                  <Form.Check
                    type="checkbox"
                    label={<span
                      className={
                        index === data.length - 1 ? 'horizontal-line' : 'horizontal-line'
                      }>
                      {item.nameNode}
                    </span>}
                    value={item.value}
                    checked={roleAdmin ? true : !!item.checked}
                    onChange={(e: any) => handleCheck(e)}
                  />
                </Form.Group>
                {item.children
                  ? item.notShow && <div className='list-permissions'>{mapPermission(item.children, item.name)}</div>
                  : null}
              </div>
            </Col>
        );
      })}
    </Row>
  );
};

export default SelectedPermissions;