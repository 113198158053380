import {PersonModels} from "../model/PersonModels";


export const INIT_PERSON: PersonModels = {
  firstName: "",
  lastName: "",
  displayName: "",
  shortName: "",
  otherName: "",
  birthDate: "",
  birthPlace: "",
  gender: "",
  startDate: "",
  endDate: "",
  phone: "",
  phoneNumber: "",
  idNumber: "",
  email: "",
  taxCode: "",
  fullName: "",
  codeDepartments: [],
  user: null,
  userId: 0,
  ccountry: null
};

export const USER_VARIABLE = {
  IS_CHANGE_PASSWORD: "user.isChangePassword"
}