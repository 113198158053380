import { FormikProvider, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { IDepartment } from "../../model/ManagerModel";
import { toast } from "react-toastify";
import {
  addNewDepartment,
  updateDepartment,
} from "../../services/ManagerServices";
import { SUCCESS_CODE } from "../../../constant";
import Autocomplete from "../../../component/input-field/Autocomplete";
import OCTTextValidator from "../../../component/input-field/OCTTextValidator";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { INIT_DEPARTMENT } from "../const/ManagerCost";
import { getListOrganizations } from "../../services/OrganizationServices";
// import { OCTAutocomplete } from "@oceantech/oceantech-ui";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { localStorageItem } from "../../../../../app/modules/utils/LocalStorage";
import OCTAutocomplete from "../../../component/input-field/OCTAutocomplete";

interface IProps {
  handleCloseDialog: () => void;
  departmentInfo: IDepartment;
  handleReload: () => Promise<void>;
}
const DepartmentDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, departmentInfo, handleReload } = props;
  const intl = useIntl();
  const { lang } = useMultiLanguage();
  const [departmentList, setDepartmentList] = useState<IDepartment[]>([]);
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
    code: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRE" }))
      .nullable(),
  });
  let accessTockenDecode = localStorageItem.get(
    KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE
  );

  useEffect(() => {
    if (!departmentList.length) return;
    let parent = departmentList?.find(
      (department: IDepartment) => department?.id === departmentInfo.parentId
    );

    formik.setValues({
      ...formik.values,
      parent,
    });
    console.log({
      ...formik.values,
      parent,
    });
  }, [departmentList]);

  useEffect(() => {
    if (departmentInfo?.id) {
      formik?.setValues(departmentInfo);
    }
  }, [departmentInfo]);

  const handleSubmit = async (values: IDepartment) => {
    let parentId = values?.parent?.id;
    let _values = {
      ...values,
      orgId: values?.orgId || accessTockenDecode?.org,
      ...(parentId ? { parentId } : {}),
    };
    delete _values.parent;
    try {
      const res = departmentInfo?.id
        ? await updateDepartment(_values)
        : await addNewDepartment(_values);
      if (res?.data?.code === SUCCESS_CODE) {
        toast.success(
          departmentInfo.id
            ? intl.formatMessage({ id: "TOAST.EDIT.SUCCESS" })
            : intl.formatMessage({ id: "TOAST.CREATE.SUCCESS" })
        );
        handleReload();
        handleCloseDialog();
      } else {
        toast.error(`${res?.data?.message}`);
      }
    } catch (err) {
      toast.error(intl.formatMessage({ id: "GENERAL.ERROR" }));
    }
  };


  const formik = useFormik({
    initialValues: departmentInfo?.id ? departmentInfo : INIT_DEPARTMENT,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangeSelect = (name: string, data: any) => {
    formik.setFieldValue(name, data);
  };

  return (
    <>
      <Modal show={true} onHide={handleCloseDialog} size="lg" centered>
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>
                {intl.formatMessage({
                  id: !departmentInfo?.id
                    ? "DEPARTMENT.ADD"
                    : "DEPARTMENT.EDIT",
                })}
                <i
                  className={`bi ${
                    departmentInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                  } text-primary ps-2 fs-5`}
                ></i>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="px-6">
                <Col xs={6}>
                  <OCTTextValidator
                    name="code"
                    lable={lang("DEPARTMENT.CODE")}
                    type={"text"}
                    isRequired
                    onChange={formik.handleChange}
                    value={formik.values.code || ""}
                    errors={formik.errors.code}
                    touched={formik.touched.code}
                  />
                </Col>
                <Col xs={6}>
                  <OCTTextValidator
                    name="name"
                    lable={lang("DEPARTMENT.NAME")}
                    type={"text"}
                    isRequired
                    onChange={formik.handleChange}
                    value={formik.values.name || ""}
                    errors={formik.errors.name}
                    touched={formik.touched.name}
                  />
                </Col>
                <Col xs={6} className={"pt-4"}>
                  <OCTAutocomplete
                    lable={lang("UNIT")}
                    options={[]}
                    value={
                      formik?.values?.orgId || departmentInfo?.orgId || null
                    }
                    name="orgId"
                    valueSearch="id"
                    isRequired
                    onChange={(selectedOption: any) => {
                      console.log(
                        "selectedOption orgId ===formik.values=>",
                        selectedOption,
                        formik.values
                      );
                      handleChangeSelect("orgId", selectedOption?.id);
                      handleChangeSelect("orgName", selectedOption?.name);
                    }}
                    getOptionValue={(option) => option.orgId}
                    searchFunction={getListOrganizations}
                    searchObject={{
                      pageSize: 999,
                      pageIndex: 1,
                    }}
                    // renderChilden="subs"
                    // disabled={isView}
                  />
                </Col>
                <Col xs={6} className={"pt-4"}>
                  <Autocomplete
                    lable={intl.formatMessage({ id: "DEPARTMENT.PARENT" })}
                    options={departmentList}
                    value={formik.values?.parent || null}
                    name="parent"
                    onChange={(selectedOption) =>
                      handleChangeSelect("parent", selectedOption)
                    }
                    errors={formik.errors?.parent}
                    touched={formik.touched?.parent}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button
                className="btn btn-secondary  btn-sm"
                onClick={handleCloseDialog}
              >
                {intl.formatMessage({
                  id: "BTN.CANCEL",
                })}
              </Button>
              <Button className="btn btn-primary btn-sm" type="submit">
                {intl.formatMessage({ id: "BTN.SAVE" })}
              </Button>
            </Modal.Footer>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
export { DepartmentDialog };
