import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getListRole } from "../../services/ManagerServices";
import { addNewUser, updateUser } from "../../services/UserServices";
import Autocomplete from "../../../component/input-field/Autocomplete";
import { INIT_USER, USER_VARIABLE } from "../const/UserConst";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import { VARIABLE } from "../../../../Constant";
import { REGEX, VARIABLE_STRING } from "../../../utils/Constant";
import { UserByOrg } from "../../model/UserModels";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../../../AppFunction";
import { OCTTextValidator } from "@oceantech/oceantech-ui";

interface IProps {
  handleCloseDialog: () => void;
  userInfo: UserByOrg;
  isView?: boolean;
}
const UserDialog: FC<IProps> = (props) => {
  const { handleCloseDialog, userInfo, isView = false } = props;
  const { lang } = useMultiLanguage();
  const [isUser, setIsUser] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    employee: isUser
      ? Yup.object().required(lang("VALIDATION.REQUIRE")).nullable()
      : Yup.object().nullable(),
    user: Yup.object().shape({
      email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(true),
      phone: Yup.string()
        .matches(REGEX.CHECK_PHONE, lang("VALIDATION.ISPHONE"))
        .nullable(true),
      password: !userInfo?.user?.id
        ? Yup.string()
            .required(lang("VALIDATION.REQUIRE"))
            .min(8, lang("REQUIRE_8"))
            .nullable()
        : Yup.mixed().nullable(),
      confirmPassword: !userInfo?.user?.id
        ? Yup.string()
            .required(lang("VALIDATION.REQUIRE"))
            .oneOf([Yup.ref("password"), null], lang("PASSWORD_INVALID"))
            .nullable()
        : Yup.mixed().nullable(),
      username: Yup.string().required(lang("VALIDATION.REQUIRE")).nullable(),
      roles: Yup.array()
        .of(Yup.object())
        .required(lang("VALIDATION.REQUIRE"))
        .nullable(true),
    }),
  });
  const handleSubmit = async (values: UserByOrg) => {
    const data: any = convertSubmitData(values);
    try {
      const res = userInfo?.user?.id
        ? await updateUser(userInfo?.user?.id, data)
        : await addNewUser(data);
      if (isSuccessfulResponse(res?.data?.code)) {
        toast.success(
          userInfo.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    }
  };

  const formik = useFormik({
    initialValues: INIT_USER,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (userInfo?.user?.id) {
      formik?.setValues(userInfo);
    }
  }, [userInfo]);

  const handleChangeSelect = (name: string, data: any) => {
    switch (name) {
      case VARIABLE_STRING.USER_ROLES:
        formik.setFieldValue(name, data);
        formik.setFieldValue("employee", null);
        break;
      case USER_VARIABLE.IS_CHANGE_PASSWORD:
        formik.setFieldValue(name, data);
        formik.setFieldValue("confirmPassword", "");
        break;
      default:
        formik.setFieldValue(name, data);
    }
  };

  const convertSubmitData = (values: UserByOrg) => ({
    ...values,
    ...values.user,
    password:
      userInfo?.user?.id && !values.user?.isChangePassword
        ? VARIABLE.DEFAULT_PASSWORD
        : values.user.password,
    confirmPassword:
      userInfo?.user?.id && !values.user?.isChangePassword
        ? VARIABLE.DEFAULT_PASSWORD
        : values.user.confirmPassword,
    accountNonExpired: true,
    accountNonLocked: true,
    active: values.active,
    isChangePassword: values.user?.isChangePassword,
    credentialsNonExpired: true,
    justCreated: true,
    roles: values.user?.roles?.map((item: any) => item.name),
  });

  return (
    <>
      <Modal show={true} onHide={handleCloseDialog} size="lg" centered>
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {lang(!userInfo?.user?.id ? "USER.ADD" : "USER.EDIT")}

              <i
                className={`bi ${
                  userInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                } text-primary ps-2 fs-5`}
              ></i>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="px-6">
              <Col xs={4}>
                <OCTTextValidator
                  name="user.username"
                  lable={lang("USER.ACCOUNT")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.username}
                  errors={formik?.errors?.user?.username}
                  touched={formik?.touched?.user?.username}
                  readOnly={Boolean(userInfo?.id)}
                  isRequired
                  disabled={isView}
                />
              </Col>
              <Col xs={4}>
                <OCTTextValidator
                  name="user.email"
                  lable={lang("USER.EMAIL")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.email}
                  disabled={isView}
                />
              </Col>
              <Col xs={4}>
                <OCTTextValidator
                  name="user.phone"
                  lable={lang("USER.PHONE")}
                  type={"text"}
                  onChange={formik.handleChange}
                  value={formik?.values?.user?.phone}
                  errors={formik?.errors?.user?.phone}
                  touched={formik?.touched?.user?.phone}
                  disabled={isView}
                />
              </Col>
            </Row>
            <Row className="px-6">
              <Col xs={4} className="pt-4">
                <Autocomplete
                  isRequired
                  lable={lang("ROLE")}
                  options={[]}
                  isMulti
                  value={formik?.values?.user?.roles || null}
                  name="user.roles"
                  getOptionValue={(option) => option.name}
                  onChange={(selectedOption) =>
                    handleChangeSelect("user.roles", selectedOption)
                  }
                  searchFunction={getListRole}
                  searchObject={{}}
                  errors={formik?.errors?.user?.roles}
                  touched={formik?.touched?.user?.roles}
                  disabled={isView}
                />
              </Col>
              {userInfo?.user?.id && (
                <Col xs={4} className="pt-4">
                  <Form.Check
                    type="switch"
                    className="ml-0 spaces mt-24"
                    name="isChangePassword"
                    onChange={(e) =>
                      handleChangeSelect(
                        USER_VARIABLE.IS_CHANGE_PASSWORD,
                        e.target.checked
                      )
                    }
                    checked={formik?.values?.user?.isChangePassword}
                    label={lang("USER.CHANGEPASSWORD")}
                    disabled={isView}
                  />
                </Col>
              )}
              {(!userInfo?.user?.id ||
                formik?.values?.user?.isChangePassword) && (
                <>
                  <Col xs={4} className="pt-4">
                    <OCTTextValidator
                      name="user.password"
                      lable={lang("USER.PASSWORD")}
                      type={"password"}
                      onChange={formik.handleChange}
                      value={formik?.values?.user?.password}
                      touched={formik?.touched?.user?.password}
                      errors={formik?.errors?.user?.password}
                      isRequired
                      disabled={isView}
                    />
                  </Col>
                  <Col xs={4} className="pt-4">
                    <OCTTextValidator
                      name="user.confirmPassword"
                      lable={lang("USER.RE_PASSWORD")}
                      type={"password"}
                      onChange={formik.handleChange}
                      value={formik?.values?.user?.confirmPassword}
                      touched={formik?.touched?.user?.confirmPassword}
                      errors={formik?.errors?.user?.confirmPassword}
                      isRequired
                      disabled={isView}
                    />
                  </Col>
                </>
              )}

              <Col xs={4} className="pt-4">
                <Form.Check
                  type="checkbox"
                  name="active"
                  className="ml-0 spaces mt-24"
                  onChange={(e) =>
                    handleChangeSelect("active", e.target.checked)
                  }
                  checked={formik?.values?.active || false}
                  label={lang("USER.ACTIVE")}
                  disabled={isView}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className="btn btn-secondary  btn-sm"
              onClick={handleCloseDialog}
            >
              {lang("BTN.CANCEL")}
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              type="submit"
              hidden={isView}
            >
              {lang("BTN.SAVE")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
export { UserDialog };
