import { Formik } from "formik";
import React, {FC, useContext, useState} from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {addNewOrganization, updateOrganization} from "../../services/PersonServices";
import {PersonModels, PersonDialogProps} from "../../model/PersonModels";
import Autocomplete from "../../../component/input-field/Autocomplete";
import useMultiLanguage from "../../../../hook/useMultiLanguage";
import TextValidator from "../../../component/input-field/OCTTextValidator";
import {handleThrowResponseMessage, isSuccessfulResponse} from "../../../../AppFunction";
import AppContext from "../../../../AppContext";
import {INIT_PERSON} from "../../const/PersonConst";
import {getListUser} from "../../../manager/services/UserServices";
import {SEARCH_OBJECT_MAX_SIZE} from "../../../utils/Constant";
import {User, UserByOrg} from "../../../manager/model/UserModels";
import {UserDialog} from "../../../manager/component/Dialog/UserDialog";


const PersonDialog: FC<PersonDialogProps> = (props) => {
  const { handleCloseDialog, personInfo = INIT_PERSON } = props;
  const { lang } = useMultiLanguage();
  const intl = useIntl();
  const {setPageLoading} = useContext(AppContext);

  const [openUserDialog, setOpenUserDialog] = useState<boolean>(false);

  const issueValidationSchema = Yup.object().shape({
    fullName: Yup.string().required(lang("VALIDATION.REQUIRE"))
      .max(25, lang("VALIDATION.MAX_FULL_NAME_LENGTH")),
    phoneNumber: Yup.string().required(lang("VALIDATION.REQUIRE"))
      .min(8, lang("VALIDATION.PHONE"))
      .max(15, lang("VALIDATION.PHONE")),
    email: Yup.string().email(lang("VALIDATION.EMAILINVALID")).nullable(true)
  });

  const handleSubmit = async (values: PersonModels) => {
    try {
      setPageLoading(true);
      const dataSubmit = convertSubmitData(values);
      const res = personInfo?.id
        ? await updateOrganization(personInfo?.id, dataSubmit)
        : await addNewOrganization(dataSubmit);
      const {code} = res?.data;
      if (isSuccessfulResponse(code)) {
        toast.success(
          personInfo?.id
            ? lang("TOAST.EDIT.SUCCESS")
            : lang("TOAST.CREATE.SUCCESS")
        );
        handleCloseDialog();
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  }

  const convertSubmitData = (values: PersonModels) => ({
    firstName: values.firstName,
    lastName: values.lastName,
    displayName: values.displayName,
    shortName: values.shortName,
    otherName: values.otherName,
    birthDate: values.birthDate,
    birthPlace: values.birthPlace,
    gender: values.gender,
    startDate: values.startDate,
    endDate: values.endDate,
    phone: values.phoneNumber,
    phoneNumber: values.phoneNumber,
    idNumber: values.idNumber,
    email: values.email,
    taxCode: values.taxCode,
    fullName: values.fullName,
    codeDepartments: values.codeDepartments,
    user: values.user,
    userId: values.userId,
    ccountry: values.ccountry,
  })

  const handleOpenUserDialog = () => {
    setOpenUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setOpenUserDialog(false);
  };

  return (
    <>
      <Formik
        initialValues={personInfo}
        validationSchema={issueValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            touched
        }) => {
          const handleChangeSelect = (data: any, name: any) => {
            setFieldValue(name, data);
          };

          return (
            <Modal show={true} onHide={handleCloseDialog} size="xl" centered>
              <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {intl.formatMessage({
                      id: !personInfo?.id ? "PERSON.ADD" : "PERSON.EDIT",
                    })}
                    <i
                      className={`bi ${
                        personInfo?.id ? "bi-pencil-square" : " bi-plus-circle"
                      } text-primary ps-2 fs-5`}
                    ></i>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="py-2">
                    <Col xs={3}>
                      <TextValidator
                        name="lastName"
                        lable={lang("PERSON.LASTNAME")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.lastName}
                        errors={errors?.lastName}
                        touched={touched?.lastName}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="firstName"
                        lable={lang("PERSON.FIRSTNAME")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.firstName}
                        errors={errors?.firstName}
                        touched={touched?.firstName}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="fullName"
                        lable={lang("PERSON.FULL_NAME")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.fullName}
                        errors={errors?.fullName}
                        touched={touched?.fullName}
                        isRequired
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="shortName"
                        lable={lang("PERSON.SHORT_NAME")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.shortName}
                        errors={errors?.shortName}
                        touched={touched?.shortName}
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={3}>
                      <TextValidator
                        name="otherName"
                        lable={lang("PERSON.OTHER_NAME")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.otherName}
                        errors={errors?.otherName}
                        touched={touched?.otherName}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="displayName"
                        lable={lang("PERSON.DISPLAY_NAME")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.displayName}
                        errors={errors?.displayName}
                        touched={touched?.displayName}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="gender"
                        lable={lang("PERSON.GENDER")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.gender}
                        errors={errors?.gender}
                        touched={touched?.gender}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="idNumber"
                        lable={lang("PERSON.ID_NUMBER")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.idNumber}
                        errors={errors?.idNumber}
                        touched={touched?.idNumber}
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={3}>
                      <TextValidator
                        name="email"
                        lable={lang("PERSON.EMAIL")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.email}
                        errors={errors?.email}
                        touched={touched?.email}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="phoneNumber"
                        lable={lang("PERSON.PHONE_NUMBER")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.phoneNumber}
                        errors={errors?.phoneNumber}
                        touched={touched?.phoneNumber}
                        isRequired
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="startDate"
                        lable={lang("PERSON.START_DATE")}
                        type="date"
                        onChange={handleChange}
                        value={values.startDate}
                        errors={errors?.startDate}
                        touched={touched?.startDate}
                      />
                    </Col>
                    <Col xs={3}>
                      <TextValidator
                        name="endDate"
                        lable={lang("PERSON.END_DATE")}
                        type={"text"}
                        onChange={handleChange}
                        value={values.endDate}
                        errors={errors?.endDate}
                        touched={touched?.endDate}
                      />
                    </Col>
                  </Row>
                  <Row className="py-2">
                    <Col xs={6}>
                      <Autocomplete
                        lable={lang("USER")}
                        options={[]}
                        value={values?.user || null}
                        name="user"
                        getOptionValue={(option) => option.id}
                        onChange={handleChangeSelect}
                        searchFunction={getListUser}
                        isAddNew
                        onAddNew={handleOpenUserDialog}
                        searchObject={SEARCH_OBJECT_MAX_SIZE}
                        getOptionLabel={(option: User) => option.username}
                      />
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                  <Button className="btn btn-secondary  btn-sm" onClick={handleCloseDialog}>
                    {intl.formatMessage({
                      id: "BTN.CANCEL",
                    })}
                  </Button>
                  <Button className="btn btn-primary btn-sm" type="submit">
                    {intl.formatMessage({ id: "BTN.SAVE" })}
                  </Button>
                </Modal.Footer>
              </Form>
              {openUserDialog && (
                <UserDialog
                  handleCloseDialog={handleCloseUserDialog}
                  userInfo={{} as UserByOrg}
                />
              )}
            </Modal>
          )
        }}
      </Formik>

    </>
  );
};
export { PersonDialog };
