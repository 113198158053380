import axios, {AxiosResponse} from "axios";
import {APIResponse} from "../../models/models";
import {UserByOrg} from "../model/UserModels";


const API_URL_USER = process.env.REACT_APP_API_URL_SSO;

export const getListUser = (searchData?: any): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || 10,
            pageIndex: searchData?.pageIndex || 1,
        },
    };
    let url = `${API_URL_USER}/users/search`;
    return axios.get(url, config);
};

export const updateUser = (id: string, user: UserByOrg): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users/${id}`;
    return axios.put(url, user);
};

export const addNewUser = (user: UserByOrg): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users`;
    return axios.post(url, user);
};

export const getUserById = (id?: any) => {
    let url = `${API_URL_USER}/users/${id}`;
    return axios.get(url);
};

export const deleteListUser = (ids: string[]): Promise<AxiosResponse<APIResponse>> => {
    let config = {
        params :{
            ids:ids?.toString()
        }
    }
    let url = `${API_URL_USER}/users/list-id`;
    return axios.delete(url,config);
};

export const deleteUser = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users/${id}`;
    return axios.delete(url);
};

export const getCurrentUser = (): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_USER}/users/current`;
    return axios.get(url);
};
