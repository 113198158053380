import { FC, useContext, useEffect, useState } from "react";
import { PersonModels } from "./model/PersonModels";
import { toast } from "react-toastify";
import { PersonDialog } from "./component/Dialog/PersonDialog";
import { OCTTable, columnNamesType } from "@oceantech/oceantech-ui";
import useMultiLanguage from "../../hook/useMultiLanguage";
import { TYPE } from "../utils/Constant";
import { IObject } from "../models/models";
import { INIT_SEARCH_OBJECT } from "../constant";
import {
  deleteOrganization,
  getListPersons,
  getOrganizationById,
} from "./services/PersonServices";
import {
  handleThrowResponseMessage,
  isSuccessfulResponse,
} from "../../AppFunction";
import { PAGINATION } from "../../Constant";
import AppContext from "../../AppContext";
import { INIT_PERSON } from "./const/PersonConst";

const Person: FC = () => {
  const { lang } = useMultiLanguage();
  const { setPageLoading } = useContext(AppContext);

  const [personListData, setPersonListData] = useState<PersonModels[]>([]);
  const [personInfo, setPersonInfo] = useState<PersonModels>(INIT_PERSON);
  const [openUnitDialog, setOpenUnitDialog] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(
    PAGINATION.TOTAL_PAGE_DEFAULT
  );
  const [totalElements, setTotalElements] = useState<number>(
    PAGINATION.TOTAL_ELEMENTS_DEFAULT
  );
  const [searchObject, setSearchObject] = useState<IObject>(INIT_SEARCH_OBJECT);

  useEffect(() => {
    if (!openUnitDialog) {
      HandleGetListPerson();
    }
  }, [searchObject?.pageIndex, searchObject?.pageSize, openUnitDialog]);

  const handleOpenOrganizationDialog = () => {
    setOpenUnitDialog(true);
  };

  const handleCloseUnitDialog = () => {
    setOpenUnitDialog(false);
    setPersonInfo(INIT_PERSON);
  };

  const HandleGetListPerson = async () => {
    try {
      setPageLoading(true);
      const res = await getListPersons(searchObject);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        setPersonListData(data?.content as PersonModels[]);
        setTotalPage(data?.totalPages || 0);
        setTotalElements(data?.pageable?.totalElements || 0);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
    }
  };

  const handleEditOrganization = async (row: any) => {
    try {
      setPageLoading(true);
      const res = await getOrganizationById(row?.id);
      const { code, data } = res.data;
      if (isSuccessfulResponse(code)) {
        data.roles = data?.roles?.map((item: any) => ({ name: item }));
        const result = {
          ...data,
          user: data,
        };

        setPersonInfo(result);
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      handleOpenOrganizationDialog();
    }
  };

  const handleDeleteOrganization = async (rowData: any) => {
    try {
      setPageLoading(true);
      const res = await deleteOrganization(rowData?.id);
      const { code } = res?.data;
      if (isSuccessfulResponse(code)) {
        toast.success(lang("GENERAL.DELETE_SUCCESS"));
        return true;
      } else {
        handleThrowResponseMessage(res);
      }
    } catch {
      toast.error(lang("GENERAL.ERROR"));
    } finally {
      setPageLoading(false);
      await HandleGetListPerson();
    }
  };

  const columns: columnNamesType[] = [
    {
      name: lang("TABLE.INDEX"),
      field: "stt",
      render: (row, index, stt) => <span>{stt}</span>,
    },
    {
      name: lang("PERSON.FULL_NAME"),
      field: "name",
      headerStyle: {
        minWidth: "150px",
      },
    },
    {
      name: lang("PERSON.DISPLAY_NAME"),
      field: "code",
      headerStyle: {
        minWidth: "250px",
      },
    },
    {
      name: lang("PERSON.EMAIL"),
      field: "type",
      headerStyle: {
        minWidth: "150px",
      },
    },
  ];

  return (
    <div>
      <OCTTable
        title={lang("PERSON.LIST")}
        isActionTableTab
        data={personListData}
        columns={columns}
        // buttonDelete
        // buttonEdit
        buttonAdd={true}
        handleOpenDialog={handleOpenOrganizationDialog}
        totalPages={totalPage}
        totalElements={totalElements}
        unSelectedAll={true}
        type={TYPE.MULTILINE}
        searchObject={searchObject}
        setSearchObject={setSearchObject}
        // handleEdit={handleEditOrganization}
        handleDelete={handleDeleteOrganization}
        handleDoubleClick={handleEditOrganization}
      />
      {openUnitDialog && (
        <PersonDialog
          handleCloseDialog={handleCloseUnitDialog}
          personInfo={personInfo}
          handleReload={HandleGetListPerson}
        />
      )}
    </div>
  );
};
export { Person };
