import axios, {AxiosResponse} from "axios";
import {APIResponse} from "../../models/models";
import {Org} from "../model/UserModels";


const API_URL_ORG = process.env.REACT_APP_XADMIN_ENDPOINT + "/organizations";

export const getListOrganizations = (searchData?: any): Promise<AxiosResponse<any>> => {
    let config = {
        params: {
            ...searchData,
            pageSize: searchData?.pageSize || 10,
            pageIndex: searchData?.pageIndex || 1,
        },
    };
    let url = `${API_URL_ORG}/page/root`;
    return axios.get(url, config);
};

export const updateOrganization = (id: string, org: Org): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.put(url, org);
};

export const addNewOrganization = (org: Org): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}`;
    return axios.post(url, org);
};

export const getOrganizationById = (id?: any) => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.get(url);
};

export const getOrganizationByCode = (code: string): Promise<AxiosResponse<APIResponse<Org>>> => {
    let config = {
        params :{
            code
        }
    }
    let url = `${API_URL_ORG}/code`;
    return axios.get(url,config);
};

export const deleteOrganization = (id: string): Promise<AxiosResponse<APIResponse>> => {
    let url = `${API_URL_ORG}/${id}`;
    return axios.delete(url);
};